// src/components/ImageModal.js
import React from 'react';

function ImageModal({ images, currentIndex, onClose, onNext, onPrevious }) {
  if (!images || images.length === 0) return null;

  return (
    <div className="modal-overlay">
      {/* Separate div to handle closing on background clicks */}
      <div className="modal-background" onClick={onClose}></div>

      <div className="modal-content">
        <div className="modal-image-container">
          <img
            src={images[currentIndex].largeUrl}
            alt={`Vehicle ${currentIndex + 1}`}
            className="modal-image"
          />
        </div>
      </div>

      <div className="modal-controls">
        <button onClick={onPrevious} disabled={currentIndex === 0}>
          &#10094; Prev
        </button>
        <button onClick={onClose}>Close Window</button>
        <button onClick={onNext} disabled={currentIndex === images.length - 1}>
          Next &#10095;
        </button>
      </div>
    </div>
  );
}

export default ImageModal;




// // src/components/ImageModal.js
// import React from 'react';

// function ImageModal({ images, currentIndex, onClose, onNext, onPrevious }) {
//   if (!images || images.length === 0) return null;

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//         <div className="modal-image-container">
//           <img
//             src={images[currentIndex].largeUrl}
//             alt={`Image ${currentIndex + 1}`}
//             className="modal-image"
//           />
//         </div>
//       </div>
//       <div className="modal-controls">
//         <button onClick={onPrevious} disabled={currentIndex === 0}>
//           &#10094; Prev
//         </button>
//         <button onClick={onClose}>Close Window</button>
//         <button onClick={onNext} disabled={currentIndex === images.length - 1}>
//           Next &#10095;
//         </button>
//       </div>
//     </div>
//   );
// }

// export default ImageModal;



// // src/components/ImageModal.js
// import React from 'react';

// function ImageModal({ images, currentIndex, onClose, onNext, onPrevious }) {
//   if (!images || images.length === 0) return null;

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//         <div className="modal-image-container">
//           <img
//             src={images[currentIndex].largeUrl}
//             alt={`Image ${currentIndex + 1}`}
//             className="modal-image"
//           />
//         </div>
//         <div className="modal-controls">
//           <button onClick={onPrevious} disabled={currentIndex === 0}>
//             &#10094; Prev
//           </button>
//           <button onClick={onClose}>
//             Close Window
//           </button>
//           <button onClick={onNext} disabled={currentIndex === images.length - 1}>
//             Next &#10095;
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ImageModal;


