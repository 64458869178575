import React, { useState } from 'react';
import axios from 'axios';
import { app } from '../firebaseConfig';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import Header from '../components/Header';
import { getAuth } from 'firebase/auth';
import { apiBaseUrl } from '../apiConfig';

import UploadProgress from '../components/UploadProgress';
import Resizer from 'react-image-file-resizer';
import PreviewProgress from '../components/PreviewProgress';


function VehicleForm() {
  const [formData, setFormData] = useState({
    year: '',
    make: '',
    model: '',
    price: '',
    color: '',
    interior: '',
    drive: '',
    transmission: '',
    vin: '',
    engine: '',
    mileage: '',
    advertisementText: '',
    facebookMarketplaceUrl: '',
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [orderInputValues, setOrderInputValues] = useState({}); 

  const [previewProgress, setPreviewProgress] = useState({ current: 0, total: 0 });
  
  


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mileage' || name === 'price' || name === 'year') {
      if (!/^\d*$/.test(value)) return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };



  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const allowedTypes = ["image/jpeg", "image/png", "image/webp", "image/gif", "image/tiff", "image/avif", "image/svg+xml"];
    const validFiles = files.filter(file => allowedTypes.includes(file.type));
  
    if (validFiles.length !== files.length) {
      alert("Some files were not in an accepted format and were ignored.");
    }
  
    setPreviewProgress({ current: 0, total: validFiles.length });
    const filesWithPreview = [];
  
    for (let i = 0; i < validFiles.length; i++) {
      const file = validFiles[i];
      const preview = await resizeFile(file);
      
      filesWithPreview.push({
        file,
        previewURL: URL.createObjectURL(preview),
        order: i + 1,
      });
      
      // Update progress for each file
      setPreviewProgress({ current: i + 1, total: validFiles.length });
    }
  
    setSelectedFiles(filesWithPreview);
    setOrderInputValues(Object.fromEntries(filesWithPreview.map((_, i) => [i, i + 1])));
    setPreviewProgress({ current: 0, total: 0 });  // Reset progress after all previews are generated
  };


  const handleOrderInputChange = (index, value) => {
    // Set the orderInputValues[index] to empty string if backspaced or cleared out
    setOrderInputValues((prevValues) => ({
      ...prevValues,
      [index]: value === '' ? '' : parseInt(value, 10), // allow empty input temporarily
    }));
  };
  
  const handleBlur = (index) => {
    setOrderInputValues((prevValues) => {
      const value = prevValues[index];
      return {
        ...prevValues,
        [index]: value === '' ? selectedFiles[index].order : parseInt(value, 10),
      };
    });
  };  


  const handleOrderUpdate = (index) => {
    const newOrder = parseInt(orderInputValues[index], 10);
    if (isNaN(newOrder) || newOrder < 1 || newOrder > selectedFiles.length) {
      // Reset to original order if input is invalid
      setOrderInputValues((prevValues) => ({
        ...prevValues,
        [index]: selectedFiles[index].order,
      }));
      return;
    }

    // Adjust the selectedFiles array to reflect the new order
    setSelectedFiles((prevFiles) => {
      // Move the file to the new position
      const updatedFiles = [...prevFiles];
      const [movedFile] = updatedFiles.splice(index, 1);
      updatedFiles.splice(newOrder - 1, 0, movedFile);

      // Update order numbers to reflect the new arrangement
      const reorderedFiles = updatedFiles.map((file, idx) => ({ ...file, order: idx + 1 }));

      // Update orderInputValues to reflect the new order in the UI
      setOrderInputValues(Object.fromEntries(reorderedFiles.map((file, idx) => [idx, file.order])));

      return reorderedFiles;
    });
  };





  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      selectedFiles.length === 0 ||
      !formData.year ||
      !formData.make ||
      !formData.model ||
      !formData.price ||
      !formData.advertisementText
    ) {
      alert("Please fill out all required fields and upload images.");
      return;
    }
  
    try {
      setIsUploading(true);
      setCurrentImageIndex(0);
      setUploadProgress(0);
  
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("You must be logged in to submit a vehicle.");
        setIsUploading(false);
        return;
      }
  
      const idToken = await currentUser.getIdToken();
      const response = await axios.post(
        `${apiBaseUrl}/listing`,
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      const vehicleId = response.data.id;
      const storage = getStorage(app, 'gs://flori-temp');
  
      for (let i = 0; i < selectedFiles.length; i++) {
        const { file, order } = selectedFiles[i];
        const uniqueFileName = `${uuidv4()}.${file.name.split('.').pop()}`;
        const fileRef = storageRef(storage, uniqueFileName);
  
        const metadata = {
          customMetadata: {
            vehicle_id: vehicleId,
            orderNumber: order,
          },
        };
  
        setCurrentImageIndex(i + 1);
  
        const uploadTask = uploadBytesResumable(fileRef, file, metadata);
  
        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progressPercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setUploadProgress(progressPercent);
            },
            (error) => {
              console.error("Upload error:", error);
              setIsUploading(false);
              reject(error);
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                console.log(`Image ${i + 1} uploaded successfully: ${downloadURL}`);
                resolve();
              } catch (urlError) {
                console.error("Error getting download URL:", urlError);
                reject(urlError);
              }
            }
          );
        });
      }
  
      setIsUploading(false);
      alert("Vehicle added successfully!");
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsUploading(false);
    }
  };
  


  const resetForm = () => {
    setFormData({
      year: '',
      make: '',
      model: '',
      price: '',
      color: '',
      interior: '',
      drive: '',
      transmission: '',
      vin: '',
      engine: '',
      mileage: '',
      advertisementText: '',
      facebookMarketplaceUrl: ''
    });
    setSelectedFiles([]);
    setCurrentImageIndex(0);
    setUploadProgress(0);
    setOrderInputValues({});
    document.querySelector('input[type="file"]').value = "";
  };



  return (
    <div className="full-width-container">
      <Header />
      <section className="content-container">
        <div className="form-container">
          <h1 className="form-title">Add New Vehicle</h1>
          <form onSubmit={handleSubmit}>

            <h3>Required Fields</h3>


            <div className="form-group">
              <label>Images:</label>
              <input type="file" accept="image/*" onChange={handleFileChange} multiple required />


              {/* {previewProgress.total > 0 && (
                <p>Generating previews {previewProgress.current} of {previewProgress.total}</p>
              )} */}


              <PreviewProgress previewProgress={previewProgress} />



              <div className="image-preview-grid">
                {selectedFiles.map((fileObj, index) => (
                  <div key={index} className="image-preview">
                    <img src={fileObj.previewURL} alt={`Preview ${index + 1}`} width="100" height="100" />
                    <input
                      type="number"
                      min="1"
                      max={selectedFiles.length}
                      value={orderInputValues[index] || ''}
                      onChange={(e) => handleOrderInputChange(index, e.target.value)}
                      onBlur={() => handleBlur(index)}
                      className="order-input"
                    />
                    <button
                      type="button"
                      onClick={() => handleOrderUpdate(index)}
                      className="update-button"
                    >
                      Update
                    </button>
                  </div>
                ))}
              </div>
            </div>


            
            <div className="form-group">
              <label>Year:</label>
              <input type="text" name="year" value={formData.year} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Make:</label>
              <input type="text" name="make" value={formData.make} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Model:</label>
              <input type="text" name="model" value={formData.model} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Advertisement Header:</label>
              <input type="text" name="advertisementText" value={formData.advertisementText} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Price:</label>
              <input type="tel" name="price" value={formData.price} onChange={handleChange} required />
            </div>

            <h3>Optional Fields</h3>

            <div className="form-group">
              <label>Facebook Marketplace URL:</label>
              <input
                type="url"
                name="facebookMarketplaceUrl"
                value={formData.facebookMarketplaceUrl}
                onChange={handleChange}
                placeholder="https://www.facebook.com/marketplace/..."
              />
            </div>

            <div className="form-group">
              <label>Color:</label>
              <input type="text" name="color" value={formData.color} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Interior:</label>
              <input type="text" name="interior" value={formData.interior} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Drive:</label>
              <input type="text" name="drive" value={formData.drive} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Transmission:</label>
              <input type="text" name="transmission" value={formData.transmission} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>VIN:</label>
              <input type="text" name="vin" value={formData.vin} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Engine:</label>
              <input type="text" name="engine" value={formData.engine} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label>Mileage:</label>
              <input type="tel" name="mileage" value={formData.mileage} onChange={handleChange} />
            </div>

            <UploadProgress
              isUploading={isUploading}
              currentImage={currentImageIndex}
              totalImages={selectedFiles.length}
              progressPercent={uploadProgress}
            />

            <button type="submit" className="submit-button" disabled={isUploading}>
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default VehicleForm;





