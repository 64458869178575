import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { setUser, clearUser } from './redux/authSlice';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import EmailLoginScreen from './pages/EmailLoginScreen';

import './styles.css';


function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) dispatch(setUser(currentUser));
      else dispatch(clearUser());
    });
    return () => unsubscribe();
  }, [dispatch]);



  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={user ? <Navigate to="/admin" /> : <EmailLoginScreen />} />
        <Route path="/admin" element={user ? <AdminPage /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;





// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import HomePage from './pages/HomePage';
// import AdminPage from './pages/AdminPage';
// import './styles.css';

// function App() {
//   return (
//     <Router>
//       <div className="full-width-container">
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/admin" element={<AdminPage />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;
