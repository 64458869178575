import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ImageModal from './ImageModal';

function VehicleCard({ vehicle, handleDelete }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // Sort images by orderNumber
  const sortedImages = [...vehicle.images].sort((a, b) => a.orderNumber - b.orderNumber);

  // Find the main image with orderNumber of 1
  const mainImage = sortedImages.find(image => image.orderNumber === 1) || sortedImages[0];

  // Open modal at the selected image index
  const openModal = (index) => {
    setCurrentImageIndex(index);
    setModalOpen(true);
  };

  // Close modal
  const closeModal = () => setModalOpen(false);

  // Navigate to the next image
  const nextImage = () => {
    if (currentImageIndex < sortedImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  // Navigate to the previous image
  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  return (
    <div className="vehicle-card">
      <div className="vehicle-ad-row">{vehicle.advertisementText}</div>
      
      <div className="vehicle-image-container">
        <img
          src={mainImage.largeThumbUrl} // Main image with orderNumber 1
          alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          className="vehicle-image"
          onClick={() => openModal(0)} // Open modal on click
        />
      </div>

      {isLoggedIn && (
        <div className="vehicle-delete-container">
          <button className="vehicle-delete-button" onClick={() => handleDelete(vehicle.id)}>
            <img src="/images/delete-icon.png" alt="Delete" style={{ width: '35px', height: '35px' }} />
          </button>
        </div>
      )}

      <div className="vehicle-title">{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</div>
      <div className="vehicle-price">{`$${Number(vehicle.price).toLocaleString()}`}</div> {/* Ensure price formatting */}
      
      <div className="vehicle-details">
        {vehicle.color && <p><strong>Color:</strong> {vehicle.color}</p>}
        {vehicle.interior && <p><strong>Interior:</strong> {vehicle.interior}</p>}
        {vehicle.drive && <p><strong>Drive:</strong> {vehicle.drive}</p>}
        {vehicle.transmission && <p><strong>Trans:</strong> {vehicle.transmission}</p>}
        {vehicle.vin && <p><strong>VIN:</strong> {vehicle.vin}</p>}
        {vehicle.engine && <p><strong>Engine:</strong> {vehicle.engine}</p>}
        {vehicle.mileage && <p><strong>Mileage:</strong> {Number(vehicle.mileage).toLocaleString()}</p>} {/* Ensure mileage formatting */}
      </div>

      <div className="vehicle-image-icons">
        {sortedImages.map((image, index) => (
          <div key={image.id} className="image-icon" onClick={() => openModal(index)}>
            <img src={image.smallThumbUrl} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>

      {isModalOpen && (
        <ImageModal
          images={sortedImages}
          currentIndex={currentImageIndex}
          onClose={closeModal}
          onNext={nextImage}
          onPrevious={prevImage}
        />
      )}
    </div>
  );
}

export default VehicleCard;



// import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import ImageModal from './ImageModal';

// function VehicleCard({ vehicle, handleDelete }) {
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);

//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

//   // Sort images by orderNumber
//   const sortedImages = [...vehicle.images].sort((a, b) => a.orderNumber - b.orderNumber);

//   // Find the main image with orderNumber of 1
//   const mainImage = sortedImages.find(image => image.orderNumber === 1) || sortedImages[0];

//   // Open modal at the selected image index
//   const openModal = (index) => {
//     setCurrentImageIndex(index);
//     setModalOpen(true);
//   };

//   // Close modal
//   const closeModal = () => setModalOpen(false);

//   // Navigate to the next image
//   const nextImage = () => {
//     if (currentImageIndex < sortedImages.length - 1) {
//       setCurrentImageIndex(currentImageIndex + 1);
//     }
//   };

//   // Navigate to the previous image
//   const prevImage = () => {
//     if (currentImageIndex > 0) {
//       setCurrentImageIndex(currentImageIndex - 1);
//     }
//   };

//   return (
//     <div className="vehicle-card">
//       <div className="vehicle-ad-row">{vehicle.advertisementText}</div>
      
//       <div className="vehicle-image-container">
//         <img
//           src={mainImage.largeThumbUrl} // Main image with orderNumber 1
//           alt={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
//           className="vehicle-image"
//           onClick={() => openModal(0)} // Open modal on click
//         />
//       </div>

//       {isLoggedIn && (
//         <div className="vehicle-delete-container">
//           <button className="vehicle-delete-button" onClick={() => handleDelete(vehicle.id)}>
//             <img src="/images/delete-icon.png" alt="Delete" style={{ width: '35px', height: '35px' }} />
//           </button>
//         </div>
//       )}

//       <div className="vehicle-title">{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</div>
//       <div className="vehicle-price">{`$${vehicle.price.toLocaleString()}`}</div>
//       <div className="vehicle-details">
//         {vehicle.color && <p><strong>Color:</strong> {vehicle.color}</p>}
//         {vehicle.interior && <p><strong>Interior:</strong> {vehicle.interior}</p>}
//         {vehicle.drive && <p><strong>Drive:</strong> {vehicle.drive}</p>}
//         {vehicle.transmission && <p><strong>Trans:</strong> {vehicle.transmission}</p>}
//         {vehicle.vin && <p><strong>VIN:</strong> {vehicle.vin}</p>}
//         {vehicle.engine && <p><strong>Engine:</strong> {vehicle.engine}</p>}
//         {vehicle.mileage && <p><strong>Mileage:</strong> {vehicle.mileage.toLocaleString()}</p>}
//       </div>

//       <div className="vehicle-image-icons">
//         {sortedImages.map((image, index) => (
//           <div key={image.id} className="image-icon" onClick={() => openModal(index)}>
//             <img src={image.smallThumbUrl} alt={`Thumbnail ${index + 1}`} />
//           </div>
//         ))}
//       </div>

//       {isModalOpen && (
//         <ImageModal
//           images={sortedImages}
//           currentIndex={currentImageIndex}
//           onClose={closeModal}
//           onNext={nextImage}
//           onPrevious={prevImage}
//         />
//       )}
//     </div>
//   );
// }

// export default VehicleCard;


