import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

export const store = configureStore({
  reducer: { auth: authReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});


// import { configureStore } from '@reduxjs/toolkit';
// import authReducer from './authSlice';

// export const store = configureStore({
//   reducer: { auth: authReducer },
// });
