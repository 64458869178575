// src/apiConfig.js
const apiConfig = {
    development: 'http://localhost:3001',  // Local development server
    production: 'https://flori-firebase-services.ue.r.appspot.com'  // Flori live server
  };
  
  // Manually switch environment here
  // Uncomment the environment you want to use and comment out the other
  
  // export const apiBaseUrl = apiConfig.development;
  export const apiBaseUrl = apiConfig.production;
  