// PreviewProgress.js
import React from 'react';
import './PreviewProgress.css';

function PreviewProgress({ previewProgress }) {
  const isGeneratingPreviews = previewProgress && previewProgress.total > 0;

  if (!isGeneratingPreviews) return null; // Don't render if not generating previews

  const progressPercent = (previewProgress.current / previewProgress.total) * 100;

  return (
    <div className="preview-progress">
      <span>
        Generating previews {previewProgress.current} of {previewProgress.total}
      </span>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
    </div>
  );
}

export default PreviewProgress;
