// src/pages/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import VehicleCard from '../components/VehicleCard';
import { getAuth } from 'firebase/auth';
import { apiBaseUrl } from '../apiConfig';


function HomePage() {
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        console.log("apiBaseUrl", apiBaseUrl);
        const response = await axios.get(`${apiBaseUrl}/listings`);
        console.log(response.data);
        setVehicles(response.data);
      } catch (error) {
        console.error("Error loading vehicles:", error);
      }
    };
    fetchVehicles();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this vehicle?");
    if (confirmDelete) {
      try {
        // Retrieve the Firebase auth token
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) {
          alert("You need to be logged in to delete this vehicle.");
          return;
        }
        const idToken = await currentUser.getIdToken();

        const response = await axios.delete(`${apiBaseUrl}/listing/${id}`, {
          headers: {
            Authorization: `Bearer ${idToken}`, // Pass the token in the Authorization header
          },
        });

        if (response.status === 200) {
          setVehicles(vehicles.filter(vehicle => vehicle.id !== id));
          // alert("Vehicle deleted successfully");
        } else {
          alert("Failed to delete vehicle");
        }
      } catch (error) {
        console.error("Error deleting vehicle:", error);
        alert("Error deleting vehicle");
      }
    }
  };

  return (
    <div className="full-width-container">
      <Header />
      <section className="content-container">
        <div className="vehicle-grid">
          {vehicles.map(vehicle => (
            <VehicleCard key={vehicle.id} vehicle={vehicle} handleDelete={handleDelete} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default HomePage;