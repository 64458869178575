import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD_NnNpkleWaVF-kwXH0ad2RFOw8jwkgCw",
    authDomain: "flori-firebase-services.firebaseapp.com",
    projectId: "flori-firebase-services",
    storageBucket: "flori-firebase-services.appspot.com",
    messagingSenderId: "1083600378844",
    appId: "1:1083600378844:web:2aecc65fea9da6da7f4d19"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, storage, auth };





// // firebaseConfig.js
// import { initializeApp } from 'firebase/app';
// import { getStorage } from 'firebase/storage';


// // Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyD_NnNpkleWaVF-kwXH0ad2RFOw8jwkgCw",
//     authDomain: "flori-firebase-services.firebaseapp.com",
//     projectId: "flori-firebase-services",
//     storageBucket: "flori-firebase-services.appspot.com",
//     messagingSenderId: "1083600378844",
//     appId: "1:1083600378844:web:2aecc65fea9da6da7f4d19"
// };


// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const storage = getStorage(app);

// export { storage };
