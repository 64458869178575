import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { clearUser } from '../redux/authSlice';

function Header() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await signOut(auth);
    dispatch(clearUser());
  };

  return (
    <div className="header-container">
      {/* Address and contact info */}
      <div className="header-info">
        <a 
          href="https://maps.app.goo.gl/tgnKNLBJqk86d3UQ9" 
          target="_blank" 
          rel="noopener noreferrer"
          className="header-link"
        >
          152 W May St, Winder, GA 30680
        </a>
        
        <div className="header-contact">
          <a href="tel:+17706884280" className="header-link">(770) 688-4280</a>

          {/* Admin button, visible only if logged in */}
          {isLoggedIn && (
            <a href="/admin" className="logout-icon">
              <img src="/images/admin.png" alt="Admin" className="admin-icon" />
            </a>
          )}

          {/* Logout button, visible only if logged in */}
          {isLoggedIn && (
            <img 
              src="/images/logout.png" 
              alt="Logout" 
              className="logout-icon"
              onClick={handleLogout} 
            />
          )}
        </div>
      </div>

      {/* Centered logo */}
      <div className="header-logo">
        <a href="/">
          <img src="/images/flori-logo.png" alt="Flori Logo" />
        </a>
      </div>

      {/* Mobile-only logout and admin buttons */}
      {isLoggedIn && (
        <div className="header-logout-mobile">
          <img 
            src="/images/logout.png" 
            alt="Logout" 
            className="logout-icon"
            onClick={handleLogout} 
          />
          <a href="/admin" className="admin-icon">
            <img src="/images/admin.png" className='admin-icon' alt="Admin" />
          </a>
        </div>
      )}


    </div>
  );
}

export default Header;








// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { signOut } from 'firebase/auth';
// import { auth } from '../firebaseConfig';
// import { clearUser } from '../redux/authSlice';

// function Header() {
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
//   const dispatch = useDispatch();

//   const handleLogout = async () => {
//     await signOut(auth);
//     dispatch(clearUser());
//   };

//   return (
//     <div className="header-container">
//       {/* Desktop address and contact info */}
//       <div className="header-info">
//         <a 
//           href="https://maps.app.goo.gl/tgnKNLBJqk86d3UQ9" 
//           target="_blank" 
//           rel="noopener noreferrer"
//           className="header-link"
//         >
//           152 W May St, Winder, GA 30680
//         </a>
//         <div className="header-contact">
//           <a href="tel:+17706884280" className="header-link">(770) 688-4280</a>
//           {isLoggedIn && (
//             <img 
//               src="/images/logout.png" 
//               alt="Logout" 
//               className="logout-icon"
//               onClick={handleLogout} 
//             />
//           )}
//         </div>
//       </div>

//       {/* Centered logo */}
//       <div className="header-logo">
//         <a href="/">
//           <img src="/images/flori-logo.png" alt="Flori Logo" />
//         </a>
//       </div>

//       {/* Mobile-only logout button */}
//       {isLoggedIn && (
//         <div className="header-logout-mobile">
//           <img 
//             src="/images/logout.png" 
//             alt="Logout" 
//             className="logout-icon"
//             onClick={handleLogout} 
//           />
//         </div>
//       )}
//     </div>
//   );
// }

// export default Header;





// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { signOut } from 'firebase/auth';
// import { auth } from '../firebaseConfig';
// import { clearUser } from '../redux/authSlice';


// function Header() {
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
//   const dispatch = useDispatch();

//   const handleLogout = async () => {
//     await signOut(auth);
//     dispatch(clearUser());
//   };

//   return (
//     <div className="header-container">
//       <div className="header-info">
//         <a 
//           href="https://maps.app.goo.gl/tgnKNLBJqk86d3UQ9" 
//           target="_blank" 
//           rel="noopener noreferrer"
//           className="header-link"
//         >
//           152 W May St, Winder, GA 30680
//         </a>
//         <div className="header-contact">
//           <a href="tel:+17706884280" className="header-link">(770) 688-4280</a>
//           {isLoggedIn && (
//             <img 
//               src="/images/logout.png" 
//               alt="Logout" 
//               className="logout-icon"
//               onClick={handleLogout} 
//             />
//           )}
//         </div>
//       </div>
//       <div className="header-logo">
//         <a href="/">
//           <img src="/images/flori-logo.png" alt="Flori Logo" />
//         </a>
//       </div>
//     </div>
//   );
// }

// export default Header;






