import React from 'react';
import './UploadProgress.css';

function UploadProgress({ isUploading, currentImage, totalImages, progressPercent }) {
  return (
    <div className="upload-progress">
      {isUploading ? (
        <span>
          Uploading Image {currentImage} of {totalImages}: {progressPercent}%
        </span>
      ) : (
        <span>Files to upload: ({totalImages})</span>
      )}
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${isUploading ? progressPercent : 0}%` }}
        ></div>
      </div>
    </div>
  );
}

export default UploadProgress;
