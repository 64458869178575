import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/authSlice';
import Header from '../components/Header';
import './EmailLoginScreen.css';

function EmailLoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      dispatch(setUser(userCredential.user));
    } catch (err) {
      setError('Failed to sign in. Please check your email and password.');
      console.error('Login failed:', err);
    }
  };

  return (
    <div className="email-login-screen">
      <Header />
      <div className="email-login-container">
        <h1 className="login-title">Flori Auto Sales Admin Login</h1>
        <form onSubmit={handleLogin} className="login-form">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@example.com"
            required
            className="login-input"
          />
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Your Password"
            required
            className="login-input"
          />
          <button type="submit" className="login-button">Sign In</button>
        </form>
        {error && <div className="error-box">{error}</div>}
      </div>
    </div>
  );
}

export default EmailLoginScreen;
